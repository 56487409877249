import React from 'react'
import './portofolio.css'
import IMG1 from '../../assets/portofolio1.jpg'
import IMG2 from '../../assets/portofolio2.jpg'
import IMG3 from '../../assets/portofolio3.jpg'
import IMG4 from '../../assets/portofolio4.jpg'
import IMG5 from '../../assets/portofolio5.jpg'
import IMG6 from '../../assets/portofolio6.jpg'


const Portofolio = () => {
  return (
    <section id='portofolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img src={IMG1} />
          </div>
          
          <h3>This is a portfolio item title</h3>
          <div className="portofolio__item-cta">
           <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
            <a href="https://dribbble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portofolio__item-cta">
          <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
          <a href="https://dribble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portofolio__item-cta">
          <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
          <a href="https://dribble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portofolio__item-cta">
          <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
          <a href="https://dribble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img src={IMG5} alt="" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portofolio__item-cta">
          <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
          <a href="https://dribble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portofolio__item">
          <div className="portofolio__item-image">
            <img  src={IMG6} alt="" />
          </div>
          <h3>This is a portfolio item title</h3>
          <a href="https://github.com/donaldo-dev" className='btn' target='_blank'>Github</a>
          <a href="https://dribble.com/Donaldo123" className='btn btn-primary' target='_blank'>Live Demo</a>
        </article>

      </div>
    </section>
  )
}

export default Portofolio