import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: 'Tina Snow',
    review: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente, odio nisi possimus repellendus ea nihil tenetur quam autem nemo, accusamus, quibusdam natus fugit at? Ipsa natus cupiditate maiores dignissimos quisquam ut similique, ullam facilis fuga quasi excepturi laborum minus dolorem!'
  },
  {
    avatar: AVTR2,
    name: 'Shatta Wale',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores quos dignissimos eius illum totam ullam velit perspiciatis veritatis provident, error hic nobis, quisquam, officiis enim blanditiis culpa tempore iste mollitia voluptatum molestiae ea? Aliquid esse dolor doloribus ex cumque eum.'
  },
  {
    avatar: AVTR3,
    name: 'Kwame Despite',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aliquam beatae rerum exercitationem soluta accusantium voluptatem tenetur illum debitis esse. Laudantium quisquam sapiente quod vitae molestiae. Quibusdam eligendi eum id nesciunt, ipsa voluptatum ad minus reprehenderit, officiis voluptas, amet deleniti!'
  },
  {
    avatar: AVTR4,
    name: 'Nana Ama McBrown',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, aut alias. Provident tempore repellendus placeat. Rem voluptatibus itaque nisi, earum aliquid obcaecati. Dolores, optio alias debitis reprehenderit ab sequi officia quisquam quod ad distinctio laboriosam similique dolorum laudantium modi dolorem.'
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      //install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>
              {review}
              </small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials